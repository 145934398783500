html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';
    font-size: 16px;
    overflow: hidden;
}

#root {
    height: 100%;
    width: 100%;
    > div {
        height: 100%;
    }
}