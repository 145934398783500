.window {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 5;
    box-sizing: border-box;
    background-color: #f1f1f1;
    min-width: 450px;
    min-height: 300px;
    /*
    border: 1px solid #d3d3d3;
    */
    text-align: center;
    border-radius: 15px;
    -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
    .window-header {
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .window-header-text {
            width: 100%;
            display: flex;
            align-items: center;
            cursor: default;
            margin-left: 10px;
        }
        .window-header-nav {
            display: flex;
            flex-direction: row;
            height: 80%;
            .window-header-nav-button {
                height: 40px;
                width: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: 0.2s ease-in;
            }
            .window-header-nav-close {
                border-radius: 0 15px 0 0;
                svg {
                    height: 20px;
                }
            }
            .window-header-nav-close:hover {
                background-color: #C62828;
                color: white;
            }
            .window-header-nav-minimise:hover,
            .window-header-nav-maximise:hover {
                background-color: rgb(200, 200, 200);
            }
        }
    }
    .window-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        div::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: white;
        }
        div::-webkit-scrollbar {
            width: 10px;
            z-index: 10;
        }
        div::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            background-color: #808080;
        }
    }
    .window-border-top-left {
        cursor: nwse-resize;
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        z-index: 6;
    }
    .window-border-top {
        cursor: ns-resize;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 5px;
    }
    .window-border-top-right {
        cursor: nesw-resize;
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        z-index: 6;
    }
    .window-border-right {
        cursor: ew-resize;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 5px;
        height: 100%;
    }
    .window-border-bottom-right {
        cursor: nwse-resize;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10px;
        height: 10px;
        z-index: 6;
    }
    .window-border-bottom {
        cursor: ns-resize;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 5px;
    }
    .window-border-bottom-left {
        cursor: nesw-resize;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10px;
        height: 10px;
        z-index: 6;
    }
    .window-border-left {
        cursor: ew-resize;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 5px;
        height: 100%;
    }
}

.home-blue {
    color: #1664c0;
}

#window-home {
    top: 100px;
    left: 100px;
    .home-wrapper {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        margin: 0 0 40px 0;
    }
    .home-intro-video {
        object-fit: cover;
        width: calc(100% - 10px);
        height: calc(100% - 40px);
        position: absolute;
        left: 0;
        top: 40px;
        z-index: -1;
    }
    .home-header {
        display: flex;
        flex-direction: column;
        height: 550px;
        color: white;
        .home-name {
            margin: 20px 20px 0 0;
            height: 10%;
            text-align: right;
        }
        .home-tagline {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            height: 70%;
            section {
                font-size: 30px;
                font-weight: 800;
                margin-top: -50px;
            }
            aside {
                margin-top: 50px;
            }
        }
    }
    .home-body {
        height: 500px;
        background-color: white;
    }
}

#window-about {
    top: 50px;
    left: 50px;
    .window-body {
        .about-wrapper {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            margin: 0 0 40px 0;
            .about-intro {
                display: flex;
                flex-direction: row;
                background-color: rgb(236, 236, 236);
                height: 100%;
                max-height: 750px;
                .about-intro-image {
                    width: 50%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}